// const app_config = {
//   backend_url: "http://localhost:5000",
// };
// const app_config = {
//   backend_url: "https://rpp-backend.vercel.app",
// };
const app_config = {
  backend_url: "https://connoisseurpresents.in",
};
export default app_config;
